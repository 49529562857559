import React from "react";
import { ReactSVG } from "react-svg";
import "./v-home.css";

import Logo from "../assets/img/logo-tipo2.svg";
import HeroImage from "../assets/img/hero-section.png";
import HeroImageUW from "../assets/img/hero-section-ultrawide.png";
import Porfolio1 from "../assets/img/Jetour.png";
import Porfolio2 from "../assets/img/MSG.png";
import Porfolio3 from "../assets/img/SGN.png";
import ValuesImg1 from "../assets/img/innovation-icon.svg";
import ValuesImg2 from "../assets/img/quality-icon.svg";
import ValuesImg3 from "../assets/img/commitment-icon.svg";
import WhatsApp from "../assets/img/whatsapp.png";
import Instagram from "../assets/img/instagram.png";
import Facebook from "../assets/img/facebook.png";

function VHome() {
  return (
    <div className="v-home">
      <header className="v-home__header">
        <ReactSVG src={Logo} className="v-home__header__logo" />
        <nav className="v-home__nav"></nav>
      </header>
      <section className="v-home__hero">
        <img src={HeroImageUW} alt="hero" className="v-home__hero-image-uw" />
        <img src={HeroImage} alt="hero" className="v-home__hero-image" />
        <div className="v-home__hero-info__container">
          <div className="v-home__hero-info">
            <p className="v-home__hero__title">
              ¡Da vida a tu presencia online!
            </p>
            <a href="https://wa.me/+18492121788?text=Me gustaria solicitar una consulta">
              <div className="v-home__hero__cta">
                Solicita una consulta gratuita
              </div>
            </a>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#f1faf9"
              fillOpacity="1"
              d="M0,160L80,149.3C160,139,320,117,480,144C640,171,800,245,960,261.3C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
        </div>
      </section>
      <section className="v-home__about-us">
        <h2 className="v-home__about-us__title">¿Quienes somos?</h2>
        <p className="v-home__about-us__text">
          <b>PlatanoGeek</b> es una empresa de desarrollo web apasionada por
          crear experiencias digitales únicas y de alto impacto. Nos
          especializamos en el diseño, desarrollo e implementación de sitios
          web, tiendas en línea y aplicaciones web a medida, utilizando las
          últimas tecnologías y tendencias del mercado.
        </p>
        <p className="v-home__about-us__text">
          Nuestra misión es ayudar a las empresas y organizaciones a alcanzar
          sus objetivos comerciales a través de soluciones web innovadoras y
          efectivas. Creemos que un sitio web bien diseñado y desarrollado puede
          ser una poderosa herramienta para atraer nuevos clientes, aumentar las
          ventas y mejorar la imagen de marca.
        </p>
        <p className="v-home__about-us__text">
          Nuestro equipo está formado por profesionales altamente cualificados y
          con experiencia en diversas áreas, como el diseño web, el desarrollo
          de software, el marketing digital y la experiencia del usuario. Nos
          apasiona lo que hacemos y estamos comprometidos con brindar a nuestros
          clientes un servicio excepcional.
        </p>
        <p className="v-home__about-us__text">
          Nuestra filosofía se basa en la colaboración, la creatividad y la
          excelencia. Trabajamos en estrecha colaboración con nuestros clientes
          para comprender sus necesidades y objetivos, y luego creamos
          soluciones web personalizadas que superan sus expectativas.
        </p>
        <div className="v-home__about-us__values">
          <h2 className="v-home__about-us__title">Nuestros valores</h2>
          <div className="v-home__about-us__cards">
            <div className="v-home__about-us__card">
              <img
                src={ValuesImg1}
                alt="Innovacion"
                className="v-home__about-us__icon"
              />
              <p className="v-home__about-us__values__text">Innovación</p>
            </div>
            <div className="v-home__about-us__card">
              <img
                src={ValuesImg2}
                alt="Innovacion"
                className="v-home__about-us__icon"
              />
              <p className="v-home__about-us__values__text">Calidad</p>
            </div>
            <div className="v-home__about-us__card">
              <img
                src={ValuesImg3}
                alt="Innovacion"
                className="v-home__about-us__icon"
              />
              <p className="v-home__about-us__values__text">Compromiso</p>
            </div>
          </div>
        </div>
      </section>
      <section className="v-home__sevices">
        <h2 className="v-home__services__title">Nuestros servicios</h2>
        <div className="v-home__sevices__cards">
          <div className="v-home__sevices__card v-home__sevices__card-a">
            <div className="v-home__sevices__card__info">
              <p className="v-home__sevices__card__info__text">
                Creación de <b>Sitios web</b>
              </p>
              <a
                href="https://wa.me/+18492121788?text=Me interesa crear un sitio web"
                className="v-home__sevices__card__info__button"
              >
                Cotizar
              </a>
            </div>
          </div>
          <div className="v-home__sevices__card v-home__sevices__card-b">
            <div className="v-home__sevices__card__info">
              <p className="v-home__sevices__card__info__text">
                Desarrollo de <b>Aplicaciones</b>
              </p>
              <a
                href="https://wa.me/+18492121788?text=Me interesa crear una App"
                className="v-home__sevices__card__info__button"
              >
                Cotizar
              </a>
            </div>
          </div>
          <div className="v-home__sevices__card v-home__sevices__card-c">
            <div className="v-home__sevices__card__info">
              <p className="v-home__sevices__card__info__text">
                Creación de <b>Tiendas online</b>
              </p>
              <a
                href="https://wa.me/+18492121788?text=Me interesa crear una tienda online"
                className="v-home__sevices__card__info__button"
              >
                Cotizar
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="v-home__dark-zone">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="v-home__dark-wave"
        >
          <path
            fill="#132f39"
            fillOpacity="1"
            d="M0,160L80,149.3C160,139,320,117,480,144C640,171,800,245,960,261.3C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
        <div className="v-home__dark-zone__container">
          <section className="v-home__porfolio">
            <h2 className="v-home__porfolio__title">Proyectos destacados</h2>
            <div className="v-home__porfolio__container">
              <a
                href="https://holajetour.do"
                className="v-home__porfolio__item"
              >
                <div className="v-home__porfolio__item-screen"></div>
                <img
                  src={Porfolio1}
                  alt="Jetour"
                  className="v-home__porfolio__image"
                />
              </a>
              <a
                href="https://mshinteriorismo.com/#/"
                className="v-home__porfolio__item"
              >
                <img
                  src={Porfolio2}
                  alt="MSG"
                  className="v-home__porfolio__image"
                />
              </a>
              <a
                href="https://www.sgn.com.do/"
                className="v-home__porfolio__item"
              >
                <img
                  src={Porfolio3}
                  alt="SGN"
                  className="v-home__porfolio__image"
                />
              </a>
            </div>
          </section>
        </div>
      </div>
      <section className="v-home__cta__whatsapp">
        <a
          href="https://wa.me/+18492121788?text=Hola PlatanoGeek"
          className="v-home__cta__whatsapp__container"
        >
          <img
            src={WhatsApp}
            alt="whatsapp"
            className="v-home__cta__whatsapp__icon"
          />
        </a>
      </section>
      <footer className="v-home__footer">
        <div className="v-home__footer__decoration-line"></div>
        <div className="v-home__footer__contact-info">
          <div className="v-home__rrss">
            <h2 className="v-home__rrss__title">Siguenos</h2>
            <div className="v-home__rrss__container">
              <a
                href="https://www.instagram.com/platanogeekrd/"
                className="v-home__rrss__img-container"
              >
                <img
                  src={Instagram}
                  alt="instagram"
                  className="v-home__rrss__icon"
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61560240074211&locale=es_LA"
                className="v-home__rrss__img-container"
              >
                <img
                  src={Facebook}
                  alt="facebook"
                  className="v-home__rrss__icon"
                />
              </a>
            </div>
          </div>
          <div className="v-home__contact">
            <h2 className="v-home__contact__title">Contáctanos</h2>
            <div className="v-home__contact__info">
              <p className="v-home__contact__info__text">+1 849 212 1788</p>
              <p className="v-home__contact__info__text">
                develop@platanogeek.com
              </p>
            </div>
          </div>
        </div>
        <p className="v-home__footer__text">
          Todos los derechos reservadost&#169; PLATANOGEEK
        </p>
      </footer>
    </div>
  );
}

export default VHome;
